import React from "react";
import "./heading.css";

const Heading = ({ subtitle, title, align }) => {
  return (
    <>
      <div
        id="heading"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: align === "left" ? "flex-start" : "center",
          flexDirection: "column",
        }}
      >
        <h3>{subtitle} </h3>
        <h1>{title} </h1>
      </div>
    </>
  );
};

export default Heading;
