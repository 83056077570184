import React from "react";
import { blog } from "../../../dummydata";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <section className="newletter">
        <div className="container flexSB">
          <div className="left row">
            <h1>Newsletter - Stay tune and get the latest update</h1>
            {/* <span>Far far away, behind the word mountains</span> */}
          </div>
          <div className="right row">
            <input type="text" placeholder="Enter email address" />
            <i className="fa fa-paper-plane"></i>
          </div>
        </div>
      </section>
      <footer>
        <div className="container padding footer">
          <div className="box logo">
            <img src="../images/Evolve-Logo.webp" alt="" />

            <p>
              Take the first step towards mastering German language and expand
              your horizon
            </p>

            <i className="fab fa-facebook-f icon"></i>
            <i className="fab fa-twitter icon"></i>
            <i className="fab fa-instagram icon"></i>
          </div>
          <div className="box link">
            <h3>Explore</h3>
            <ul>
              <li>About Us</li>
              <li>Services</li>
              <li>Courses</li>
              <li>Blog</li>
              <li>Contact us</li>
            </ul>
          </div>
          <div className="box link">
            <h3>Quick Links</h3>
            <ul>
              <li>Contact Us</li>
              <li>Pricing</li>
              <li>Terms & Conditions</li>
              <li>Privacy</li>
              <li>Feedbacks</li>
            </ul>
          </div>
          <div className="box last">
            <h3>Have a Questions?</h3>
            <ul>
              <li>
                <i className="fa fa-phone-alt"></i>
                <div className="mail-address">
                  <div style={{ paddingBottom: "10px" }}>+91 9946113063</div>
                  <div style={{ paddingBottom: "10px" }}>+91 7012085694</div>
                </div>
              </li>
              <li>
                <i className="fa fa-paper-plane"></i>
                <div className="mail-address">
                  <div style={{ paddingBottom: "10px" }}>
                    info@evolvelanguageacademy.com
                  </div>
                  <div style={{ paddingBottom: "10px" }}>
                    germany@evolvelanguageacademy.com
                  </div>
                  <div>examination@evolvelanguageacademy.com</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
