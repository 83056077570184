import React from "react";
import { price } from "../../dummydata";

const PriceCard = () => {
  return (
    <>
      {price.map((val) => (
        <div className="items shadow" style={{ position: "relative" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "50px",
              width: "100%",
            }}
          >
            <h4>{val.name}</h4>
            <span
              style={{ fontSize: "10px", position: "absolute", top: "35%" }}
            >
              {val?.desc}
            </span>
          </div>
          <button className="outline-btn">GET STARTED</button>
        </div>
      ))}
    </>
  );
};

export default PriceCard;
